import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { ChangeDetectorRef, Injector, Component, ChangeDetectionStrategy, Optional, Self, Inject, ViewChild, Input, HostBinding, HostListener, Directive, NgModule } from '@angular/core';
import * as i8 from '@angular/forms';
import { NgControl } from '@angular/forms';
import { MASKITO_DEFAULT_OPTIONS } from '@maskito/core';
import { maskitoDateRangeOptionsGenerator } from '@maskito/kit';
import * as i3 from '@taiga-ui/cdk';
import { AbstractTuiNullableControl, ALWAYS_FALSE_HANDLER, TuiMonth, changeDateSeparator, TUI_FIRST_DAY, TUI_LAST_DAY, tuiNullableSame, tuiIsPresent, DATE_RANGE_FILLER_LENGTH, TuiDayRange, DATE_FILLER_LENGTH, RANGE_SEPARATOR_CHAR, TUI_IS_MOBILE, TUI_DATE_FORMAT, TUI_DATE_SEPARATOR, tuiAsFocusableItemAccessor, tuiAsControl, tuiPure, TuiActiveZoneModule, TuiLetModule } from '@taiga-ui/cdk';
import * as i1 from '@taiga-ui/core';
import { TUI_DEFAULT_MARKER_HANDLER, TuiDialogService, TUI_TEXTFIELD_SIZE, TuiPrimitiveTextfieldComponent, AbstractTuiTextfieldHost, tuiAsTextfieldHost, TuiWrapperModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiHostedDropdownModule, TuiSvgModule, TuiTextfieldComponent } from '@taiga-ui/core';
import { MAX_DAY_RANGE_LENGTH_MAPPER, TUI_DATE_MODE_MASKITO_ADAPTER } from '@taiga-ui/kit/constants';
import { TUI_MOBILE_CALENDAR, TUI_DATE_TEXTS, TUI_DATE_RANGE_VALUE_TRANSFORMER, TUI_INPUT_DATE_OPTIONS, tuiDateStreamWithTransformer } from '@taiga-ui/kit/tokens';
import * as i7 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusComponent, PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { map, takeUntil } from 'rxjs/operators';
import * as i2 from '@taiga-ui/kit/components/calendar-range';
import { TuiCalendarRangeModule } from '@taiga-ui/kit/components/calendar-range';
import * as i4 from '@taiga-ui/kit/directives';
import { TuiValueAccessorModule } from '@taiga-ui/kit/directives';
import * as i5 from '@maskito/angular';
import { MaskitoModule } from '@maskito/angular';
import * as i6 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i9 from 'rxjs';
function TuiInputDateRangeComponent_tui_primitive_textfield_1_div_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r8 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", text_r8, " ");
  }
}
const _c0 = a0 => ({
  $implicit: a0
});
function TuiInputDateRangeComponent_tui_primitive_textfield_1_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵtemplate(1, TuiInputDateRangeComponent_tui_primitive_textfield_1_div_3_ng_container_1_Template, 2, 1, "ng-container", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r6.computedContent)("polymorpheusOutletContext", i0.ɵɵpureFunction1(2, _c0, ctx_r6.value));
  }
}
function TuiInputDateRangeComponent_tui_primitive_textfield_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tui-primitive-textfield", 4);
    i0.ɵɵlistener("valueChange", function TuiInputDateRangeComponent_tui_primitive_textfield_1_Template_tui_primitive_textfield_valueChange_0_listener($event) {
      i0.ɵɵrestoreView(_r10);
      const ctx_r9 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r9.onValueChange($event));
    });
    i0.ɵɵprojection(1);
    i0.ɵɵprojection(2, 1, ["ngProjectAs", "input", 5, ["input"]]);
    i0.ɵɵtemplate(3, TuiInputDateRangeComponent_tui_primitive_textfield_1_div_3_Template, 2, 4, "div", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dateFiller_r5 = ctx.tuiLet;
    const ctx_r0 = i0.ɵɵnextContext();
    const _r2 = i0.ɵɵreference(4);
    i0.ɵɵproperty("disabled", ctx_r0.computedDisabled)("invalid", ctx_r0.computedInvalid)("maskito", ctx_r0.computedMask)("nativeId", ctx_r0.nativeId)("pseudoActive", ctx_r0.pseudoActive)("pseudoFocus", ctx_r0.innerPseudoFocused)("pseudoHover", ctx_r0.pseudoHover)("readOnly", ctx_r0.readOnly)("tuiTextfieldFiller", ctx_r0.getComputedRangeFiller(dateFiller_r5 || ""))("tuiTextfieldIcon", ctx_r0.calendarIcon && _r2)("value", ctx_r0.computedValue);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r0.showValueTemplate);
  }
}
function TuiInputDateRangeComponent_ng_template_3_tui_svg_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tui-svg", 9);
    i0.ɵɵlistener("click", function TuiInputDateRangeComponent_ng_template_3_tui_svg_0_Template_tui_svg_click_0_listener() {
      i0.ɵɵrestoreView(_r14);
      const ctx_r13 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r13.onIconClick());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const src_r12 = ctx.polymorpheusOutlet;
    const ctx_r11 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("t-icon", !ctx_r11.computedDisabled);
    i0.ɵɵproperty("src", src_r12);
  }
}
function TuiInputDateRangeComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TuiInputDateRangeComponent_ng_template_3_tui_svg_0_Template, 1, 3, "tui-svg", 8);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r1.calendarIcon)("polymorpheusOutletContext", i0.ɵɵpureFunction1(2, _c0, ctx_r1.size));
  }
}
function TuiInputDateRangeComponent_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r16 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tui-calendar-range", 10);
    i0.ɵɵlistener("valueChange", function TuiInputDateRangeComponent_ng_template_5_Template_tui_calendar_range_valueChange_0_listener($event) {
      i0.ɵɵrestoreView(_r16);
      const ctx_r15 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r15.onRangeChange($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("defaultViewedMonth", ctx_r3.defaultViewedMonth)("disabledItemHandler", ctx_r3.disabledItemHandler)("items", ctx_r3.items)("markerHandler", ctx_r3.markerHandler)("max", ctx_r3.computedMax)("maxLength", ctx_r3.maxLength)("min", ctx_r3.computedMin)("minLength", ctx_r3.minLength)("value", ctx_r3.value);
  }
}
const _c1 = ["*", [["input"]]];
const _c2 = ["*", "input"];
class TuiInputDateRangeComponent extends AbstractTuiNullableControl {
  constructor(control, cdr, injector, isMobile, dialogs, mobileCalendar, dateFormat, dateSeparator, dateTexts$, valueTransformer, options, textfieldSize) {
    super(control, cdr, valueTransformer);
    this.injector = injector;
    this.isMobile = isMobile;
    this.dialogs = dialogs;
    this.mobileCalendar = mobileCalendar;
    this.dateFormat = dateFormat;
    this.dateSeparator = dateSeparator;
    this.dateTexts$ = dateTexts$;
    this.valueTransformer = valueTransformer;
    this.options = options;
    this.textfieldSize = textfieldSize;
    this.disabledItemHandler = ALWAYS_FALSE_HANDLER;
    this.markerHandler = TUI_DEFAULT_MARKER_HANDLER;
    this.defaultViewedMonth = TuiMonth.currentLocal();
    this.items = [];
    this.min = this.options.min;
    this.max = this.options.max;
    this.minLength = null;
    this.maxLength = null;
    this.open = false;
    this.maxLengthMapper = MAX_DAY_RANGE_LENGTH_MAPPER;
    this.dateFiller$ = this.dateTexts$.pipe(map(dateTexts => changeDateSeparator(dateTexts[this.dateFormat], this.dateSeparator)));
  }
  get size() {
    return this.textfieldSize.size;
  }
  get computedMin() {
    var _a;
    return (_a = this.min) !== null && _a !== void 0 ? _a : TUI_FIRST_DAY;
  }
  get computedMax() {
    var _a;
    return (_a = this.max) !== null && _a !== void 0 ? _a : TUI_LAST_DAY;
  }
  get nativeFocusableElement() {
    var _a, _b;
    return (_b = (_a = this.textfield) === null || _a === void 0 ? void 0 : _a.nativeFocusableElement) !== null && _b !== void 0 ? _b : null;
  }
  get focused() {
    var _a;
    return !!((_a = this.textfield) === null || _a === void 0 ? void 0 : _a.focused);
  }
  get computedMobile() {
    return this.isMobile && !!this.mobileCalendar;
  }
  get calendarIcon() {
    return this.options.icon;
  }
  get canOpen() {
    return this.interactive && !this.computedMobile;
  }
  get computedExampleText() {
    var _a, _b;
    return this.items.length ? ((_b = (_a = this.textfield) === null || _a === void 0 ? void 0 : _a.nativeFocusableElement) === null || _b === void 0 ? void 0 : _b.placeholder) || '' : '';
  }
  get computedMask() {
    var _a, _b;
    /**
     * TODO: we can delete this workaround in v4.0
     * after solving this issue:
     * https://github.com/taiga-family/maskito/issues/604
     */
    const nativeValueIsNotSynced = ((_b = (_a = this.textfield) === null || _a === void 0 ? void 0 : _a.nativeFocusableElement) === null || _b === void 0 ? void 0 : _b.value) !== this.computedValue;
    return this.activePeriod || nativeValueIsNotSynced ? MASKITO_DEFAULT_OPTIONS : this.calculateMask(this.dateFormat, this.dateSeparator, this.computedMin, this.computedMax, this.minLength, this.maxLength);
  }
  get activePeriod() {
    return this.items.find(item => tuiNullableSame(this.value, item.range, (a, b) => a.from.daySame(b.from.dayLimit(this.min, this.max)) && a.to.daySame(b.to.dayLimit(this.min, this.max)))) || null;
  }
  get computedValue() {
    const {
      value,
      nativeValue,
      activePeriod
    } = this;
    if (activePeriod) {
      return String(activePeriod);
    }
    return value ? value.getFormattedDayRange(this.dateFormat, this.dateSeparator) : nativeValue;
  }
  get showValueTemplate() {
    return tuiIsPresent(this.value) && !this.focused;
  }
  get computedContent() {
    var _a;
    return ((_a = this.activePeriod) === null || _a === void 0 ? void 0 : _a.content) || this.computedValue;
  }
  get innerPseudoFocused() {
    if (this.pseudoFocus === false) {
      return false;
    }
    if (this.open || this.computedFocused) {
      return true;
    }
    return null;
  }
  get nativeValue() {
    var _a;
    return ((_a = this.nativeFocusableElement) === null || _a === void 0 ? void 0 : _a.value) || '';
  }
  set nativeValue(value) {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.value = value;
    }
  }
  onClick() {
    if (!this.isMobile) {
      this.toggle();
    }
  }
  getComputedRangeFiller(dateFiller) {
    return this.activePeriod ? '' : this.getDateRangeFiller(dateFiller);
  }
  onIconClick() {
    if (!this.computedMobile || !this.mobileCalendar) {
      return;
    }
    this.dialogs.open(new PolymorpheusComponent(this.mobileCalendar, this.injector), {
      size: 'fullscreen',
      closeable: false,
      data: {
        min: this.maxLengthMapper(this.computedMin, this.value, this.maxLength, true),
        max: this.maxLengthMapper(this.computedMax, this.value, this.maxLength, false),
        disabledItemHandler: this.disabledItemHandler
      }
    }).pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.value = value;
    });
  }
  onOpenChange(open) {
    this.open = open;
  }
  onValueChange(value) {
    if (this.control) {
      this.control.updateValueAndValidity({
        emitEvent: false
      });
    }
    if (!value) {
      this.onOpenChange(true);
    }
    this.value = value.length === DATE_RANGE_FILLER_LENGTH ? TuiDayRange.normalizeParse(value, this.dateFormat) : null;
  }
  onRangeChange(range) {
    this.toggle();
    this.focusInput();
    if (!range) {
      this.nativeValue = '';
    }
    this.value = range;
  }
  // TODO: investigate if it is used anywhere and (if not) delete it in v4.0
  onItemSelect(item) {
    this.toggle();
    this.focusInput();
    if (typeof item !== 'string') {
      this.value = item.range.dayLimit(this.min, this.max);
      return;
    }
    if (this.activePeriod === null) {
      return;
    }
    this.value = null;
    this.nativeValue = '';
  }
  onActiveZone(focused) {
    this.updateFocused(focused);
    if (!focused && !this.itemSelected && (this.nativeValue.length === DATE_FILLER_LENGTH || this.nativeValue.length === DATE_FILLER_LENGTH + RANGE_SEPARATOR_CHAR.length)) {
      this.value = TuiDayRange.normalizeParse(this.nativeValue, this.dateFormat);
    }
  }
  writeValue(value) {
    super.writeValue(value);
    this.nativeValue = value ? this.computedValue : '';
  }
  valueIdenticalComparator(oldValue, newValue) {
    return tuiNullableSame(oldValue, newValue, (a, b) => a.daySame(b));
  }
  calculateMask(dateFormat, separator, min, max, minLength, maxLength) {
    return maskitoDateRangeOptionsGenerator({
      separator,
      mode: TUI_DATE_MODE_MASKITO_ADAPTER[dateFormat],
      min: min.toLocalNativeDate(),
      max: max.toLocalNativeDate(),
      minLength: minLength || {},
      maxLength: maxLength || {}
    });
  }
  get itemSelected() {
    return this.items.findIndex(item => String(item) === this.nativeValue) !== -1;
  }
  toggle() {
    this.open = !this.open;
  }
  focusInput(preventScroll = false) {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.focus({
        preventScroll
      });
    }
  }
  getDateRangeFiller(dateFiller) {
    return `${dateFiller}${RANGE_SEPARATOR_CHAR}${dateFiller}`;
  }
}
TuiInputDateRangeComponent.ɵfac = function TuiInputDateRangeComponent_Factory(t) {
  return new (t || TuiInputDateRangeComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(Injector), i0.ɵɵdirectiveInject(TUI_IS_MOBILE), i0.ɵɵdirectiveInject(TuiDialogService), i0.ɵɵdirectiveInject(TUI_MOBILE_CALENDAR, 8), i0.ɵɵdirectiveInject(TUI_DATE_FORMAT), i0.ɵɵdirectiveInject(TUI_DATE_SEPARATOR), i0.ɵɵdirectiveInject(TUI_DATE_TEXTS), i0.ɵɵdirectiveInject(TUI_DATE_RANGE_VALUE_TRANSFORMER, 8), i0.ɵɵdirectiveInject(TUI_INPUT_DATE_OPTIONS), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_SIZE));
};
TuiInputDateRangeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiInputDateRangeComponent,
  selectors: [["tui-input-date-range"]],
  viewQuery: function TuiInputDateRangeComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiPrimitiveTextfieldComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textfield = _t.first);
    }
  },
  hostVars: 1,
  hostBindings: function TuiInputDateRangeComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function TuiInputDateRangeComponent_click_HostBindingHandler() {
        return ctx.onClick();
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
    }
  },
  inputs: {
    disabledItemHandler: "disabledItemHandler",
    markerHandler: "markerHandler",
    defaultViewedMonth: "defaultViewedMonth",
    items: "items",
    min: "min",
    max: "max",
    minLength: "minLength",
    maxLength: "maxLength"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiInputDateRangeComponent), tuiAsControl(TuiInputDateRangeComponent), tuiDateStreamWithTransformer(TUI_DATE_RANGE_VALUE_TRANSFORMER)]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c2,
  decls: 7,
  vars: 6,
  consts: [[1, "t-hosted", 3, "canOpen", "content", "open", "openChange", "tuiActiveZoneChange"], ["automation-id", "tui-input-date-range__textfield", "tuiValueAccessor", "", "class", "t-textfield", 3, "disabled", "invalid", "maskito", "nativeId", "pseudoActive", "pseudoFocus", "pseudoHover", "readOnly", "tuiTextfieldFiller", "tuiTextfieldIcon", "value", "valueChange", 4, "tuiLet"], ["iconContent", ""], ["dropdown", ""], ["automation-id", "tui-input-date-range__textfield", "tuiValueAccessor", "", 1, "t-textfield", 3, "disabled", "invalid", "maskito", "nativeId", "pseudoActive", "pseudoFocus", "pseudoHover", "readOnly", "tuiTextfieldFiller", "tuiTextfieldIcon", "value", "valueChange"], ["ngProjectAs", "tuiContent", "class", "t-value", 5, ["tuiContent"], 4, "ngIf"], ["ngProjectAs", "tuiContent", 5, ["tuiContent"], 1, "t-value"], [4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["appearance", "icon", "automation-id", "tui-input-date-range__icon", "tuiWrapper", "", 3, "t-icon", "src", "click", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["appearance", "icon", "automation-id", "tui-input-date-range__icon", "tuiWrapper", "", 3, "src", "click"], [3, "defaultViewedMonth", "disabledItemHandler", "items", "markerHandler", "max", "maxLength", "min", "minLength", "value", "valueChange"]],
  template: function TuiInputDateRangeComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c1);
      i0.ɵɵelementStart(0, "tui-hosted-dropdown", 0);
      i0.ɵɵlistener("openChange", function TuiInputDateRangeComponent_Template_tui_hosted_dropdown_openChange_0_listener($event) {
        return ctx.onOpenChange($event);
      })("tuiActiveZoneChange", function TuiInputDateRangeComponent_Template_tui_hosted_dropdown_tuiActiveZoneChange_0_listener($event) {
        return ctx.onActiveZone($event);
      });
      i0.ɵɵtemplate(1, TuiInputDateRangeComponent_tui_primitive_textfield_1_Template, 4, 12, "tui-primitive-textfield", 1);
      i0.ɵɵpipe(2, "async");
      i0.ɵɵtemplate(3, TuiInputDateRangeComponent_ng_template_3_Template, 1, 4, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor)(5, TuiInputDateRangeComponent_ng_template_5_Template, 1, 9, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      const _r4 = i0.ɵɵreference(6);
      i0.ɵɵproperty("canOpen", ctx.canOpen)("content", _r4)("open", ctx.open && ctx.canOpen);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("tuiLet", i0.ɵɵpipeBind1(2, 4, ctx.dateFiller$));
    }
  },
  dependencies: [i1.TuiHostedDropdownComponent, i1.TuiPrimitiveTextfieldComponent, i1.TuiSvgComponent, i2.TuiCalendarRangeComponent, i3.TuiActiveZoneDirective, i3.TuiLetDirective, i1.TuiPrimitiveTextfieldDirective, i4.TuiValueAccessorDirective, i5.MaskitoDirective, i1.TuiTextfieldFillerDirective, i1.TuiTextfieldIconDirective, i6.NgIf, i7.PolymorpheusOutletDirective, i1.TuiWrapperDirective, i6.AsyncPipe],
  styles: ["[_nghost-%COMP%]{display:block;border-radius:var(--tui-radius-m);text-align:left}._disabled[_nghost-%COMP%]{pointer-events:none}.t-hosted[_ngcontent-%COMP%]{display:block;border-radius:inherit}.t-textfield[_ngcontent-%COMP%]{border-radius:inherit;text-align:inherit}.t-icon[_ngcontent-%COMP%]{pointer-events:auto}.t-value[_ngcontent-%COMP%]{display:flex;width:100%;align-items:center}"],
  changeDetection: 0
});
__decorate([tuiPure], TuiInputDateRangeComponent.prototype, "calculateMask", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputDateRangeComponent, [{
    type: Component,
    args: [{
      selector: 'tui-input-date-range',
      templateUrl: './input-date-range.template.html',
      styleUrls: ['./input-date-range.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiInputDateRangeComponent), tuiAsControl(TuiInputDateRangeComponent), tuiDateStreamWithTransformer(TUI_DATE_RANGE_VALUE_TRANSFORMER)]
    }]
  }], function () {
    return [{
      type: i8.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i0.Injector,
      decorators: [{
        type: Inject,
        args: [Injector]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_MOBILE]
      }]
    }, {
      type: i1.TuiDialogService,
      decorators: [{
        type: Inject,
        args: [TuiDialogService]
      }]
    }, {
      type: i0.Type,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TUI_MOBILE_CALENDAR]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_DATE_FORMAT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_DATE_SEPARATOR]
      }]
    }, {
      type: i9.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_DATE_TEXTS]
      }]
    }, {
      type: i3.AbstractTuiValueTransformer,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TUI_DATE_RANGE_VALUE_TRANSFORMER]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_INPUT_DATE_OPTIONS]
      }]
    }, {
      type: i1.TuiTextfieldSizeDirective,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_SIZE]
      }]
    }];
  }, {
    textfield: [{
      type: ViewChild,
      args: [TuiPrimitiveTextfieldComponent]
    }],
    disabledItemHandler: [{
      type: Input
    }],
    markerHandler: [{
      type: Input
    }],
    defaultViewedMonth: [{
      type: Input
    }],
    items: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    minLength: [{
      type: Input
    }],
    maxLength: [{
      type: Input
    }],
    size: [{
      type: HostBinding,
      args: ['attr.data-size']
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }],
    calculateMask: []
  });
})();
class TuiInputDateRangeDirective extends AbstractTuiTextfieldHost {
  get value() {
    return this.host.computedValue;
  }
  onValueChange(value) {
    this.host.onValueChange(value);
  }
  process(input) {
    input.inputMode = 'numeric';
  }
  ngDoCheck() {
    if (this.host.nativeFocusableElement) {
      this.host.nativeFocusableElement.placeholder = this.host.computedExampleText;
    }
  }
}
TuiInputDateRangeDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiInputDateRangeDirective_BaseFactory;
  return function TuiInputDateRangeDirective_Factory(t) {
    return (ɵTuiInputDateRangeDirective_BaseFactory || (ɵTuiInputDateRangeDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputDateRangeDirective)))(t || TuiInputDateRangeDirective);
  };
})();
TuiInputDateRangeDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiInputDateRangeDirective,
  selectors: [["tui-input-date-range"]],
  features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiInputDateRangeDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputDateRangeDirective, [{
    type: Directive,
    args: [{
      selector: 'tui-input-date-range',
      providers: [tuiAsTextfieldHost(TuiInputDateRangeDirective)]
    }]
  }], null, null);
})();
class TuiInputDateRangeModule {}
TuiInputDateRangeModule.ɵfac = function TuiInputDateRangeModule_Factory(t) {
  return new (t || TuiInputDateRangeModule)();
};
TuiInputDateRangeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiInputDateRangeModule
});
TuiInputDateRangeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, MaskitoModule, TuiActiveZoneModule, TuiLetModule, PolymorpheusModule, TuiWrapperModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiHostedDropdownModule, TuiSvgModule, TuiCalendarRangeModule, TuiValueAccessorModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputDateRangeModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MaskitoModule, TuiActiveZoneModule, TuiLetModule, PolymorpheusModule, TuiWrapperModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiHostedDropdownModule, TuiSvgModule, TuiCalendarRangeModule, TuiValueAccessorModule],
      declarations: [TuiInputDateRangeComponent, TuiInputDateRangeDirective],
      exports: [TuiInputDateRangeComponent, TuiInputDateRangeDirective, TuiTextfieldComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiInputDateRangeComponent, TuiInputDateRangeDirective, TuiInputDateRangeModule };
