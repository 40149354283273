import * as i0 from '@angular/core';
import { Pipe, Inject, NgModule } from '@angular/core';
import { TUI_FIRST_DAY_OF_WEEK } from '@taiga-ui/core/tokens';
import { map } from 'rxjs/operators';
function convertToSundayFirstWeekFormat(weekDaysNames) {
  const sundayIndex = weekDaysNames.length - 1;
  return [weekDaysNames[sundayIndex], ...weekDaysNames.slice(0, sundayIndex)];
}
class TuiOrderWeekDaysPipe {
  constructor(firstDayOfWeekIndex) {
    this.firstDayOfWeekIndex = firstDayOfWeekIndex;
  }
  transform(mondayFirstWeekDays$) {
    return mondayFirstWeekDays$.pipe(map(convertToSundayFirstWeekFormat), map(weekDays => [...weekDays.slice(this.firstDayOfWeekIndex), ...weekDays.slice(0, this.firstDayOfWeekIndex)]));
  }
}
TuiOrderWeekDaysPipe.ɵfac = function TuiOrderWeekDaysPipe_Factory(t) {
  return new (t || TuiOrderWeekDaysPipe)(i0.ɵɵdirectiveInject(TUI_FIRST_DAY_OF_WEEK, 16));
};
TuiOrderWeekDaysPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "tuiOrderWeekDays",
  type: TuiOrderWeekDaysPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiOrderWeekDaysPipe, [{
    type: Pipe,
    args: [{
      name: 'tuiOrderWeekDays'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_FIRST_DAY_OF_WEEK]
      }]
    }];
  }, null);
})();
class TuiOrderWeekDaysPipeModule {}
TuiOrderWeekDaysPipeModule.ɵfac = function TuiOrderWeekDaysPipeModule_Factory(t) {
  return new (t || TuiOrderWeekDaysPipeModule)();
};
TuiOrderWeekDaysPipeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiOrderWeekDaysPipeModule
});
TuiOrderWeekDaysPipeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiOrderWeekDaysPipeModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiOrderWeekDaysPipe],
      exports: [TuiOrderWeekDaysPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiOrderWeekDaysPipe, TuiOrderWeekDaysPipeModule };
