import * as i0 from '@angular/core';
import { Pipe, Inject, NgModule } from '@angular/core';
import * as i1 from '@taiga-ui/cdk';
import { DAYS_IN_WEEK, tuiAssert, tuiInRange, TuiDay } from '@taiga-ui/cdk';
import { TUI_FIRST_DAY_OF_WEEK } from '@taiga-ui/core/tokens';

/**
 * Computes day of week offset of the beginning of the month
 */
const getMonthStartDaysOffset = (month, firstDayOfWeek) => {
  const startMonthOffsetFromSunday = new Date(month.year, month.month, 1).getDay();
  return startMonthOffsetFromSunday >= firstDayOfWeek ? startMonthOffsetFromSunday - firstDayOfWeek : DAYS_IN_WEEK - (firstDayOfWeek - startMonthOffsetFromSunday);
};
/**
 * Calculated day on a calendar grid
 * @return resulting day on these coordinates (could exceed passed month)
 */
const getDayFromMonthRowCol = ({
  month,
  rowIndex,
  colIndex,
  firstDayOfWeek
}) => {
  ngDevMode && tuiAssert.assert(Number.isInteger(rowIndex));
  ngDevMode && tuiAssert.assert(tuiInRange(rowIndex, 0, 6));
  ngDevMode && tuiAssert.assert(Number.isInteger(colIndex));
  ngDevMode && tuiAssert.assert(tuiInRange(colIndex, 0, DAYS_IN_WEEK));
  let day = rowIndex * DAYS_IN_WEEK + colIndex - getMonthStartDaysOffset(month, firstDayOfWeek) + 1;
  if (day > month.daysCount) {
    day -= month.daysCount;
    month = month.append({
      month: 1
    });
  }
  if (day <= 0) {
    month = month.append({
      month: -1
    });
    day = month.daysCount + day;
  }
  return new TuiDay(month.year, month.month, day);
};
const CALENDAR_ROWS_COUNT = 6;
class TuiCalendarSheetPipe {
  constructor(firstDayOfWeek) {
    this.firstDayOfWeek = firstDayOfWeek;
    this.currentMonth = null;
    this.currentSheet = [];
  }
  transform(month, showAdjacentDays = false) {
    var _a;
    if ((_a = this.currentMonth) === null || _a === void 0 ? void 0 : _a.monthSame(month)) {
      return this.currentSheet;
    }
    const sheet = [];
    for (let rowIndex = 0; rowIndex < CALENDAR_ROWS_COUNT; rowIndex++) {
      const row = [];
      for (let colIndex = 0; colIndex < DAYS_IN_WEEK; colIndex++) {
        const day = getDayFromMonthRowCol({
          month,
          rowIndex,
          colIndex,
          firstDayOfWeek: this.firstDayOfWeek
        });
        const isPrevMonthDay = (day, relativeToMonth = month) => day.year < relativeToMonth.year || day.month < relativeToMonth.month;
        const isNextMonthDay = (day, relativeToMonth = month) => day.year > relativeToMonth.year || day.month > relativeToMonth.month;
        if (isPrevMonthDay(day) && !showAdjacentDays) {
          continue;
        }
        if (isNextMonthDay(day) && !showAdjacentDays) {
          break;
        }
        row.push(day);
      }
      sheet.push(row);
    }
    this.currentSheet = sheet.filter(row => row.length);
    this.currentMonth = month;
    return this.currentSheet;
  }
}
TuiCalendarSheetPipe.ɵfac = function TuiCalendarSheetPipe_Factory(t) {
  return new (t || TuiCalendarSheetPipe)(i0.ɵɵdirectiveInject(TUI_FIRST_DAY_OF_WEEK, 16));
};
TuiCalendarSheetPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "tuiCalendarSheet",
  type: TuiCalendarSheetPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCalendarSheetPipe, [{
    type: Pipe,
    args: [{
      name: 'tuiCalendarSheet'
    }]
  }], function () {
    return [{
      type: i1.TuiDayOfWeek,
      decorators: [{
        type: Inject,
        args: [TUI_FIRST_DAY_OF_WEEK]
      }]
    }];
  }, null);
})();
class TuiCalendarSheetPipeModule {}
TuiCalendarSheetPipeModule.ɵfac = function TuiCalendarSheetPipeModule_Factory(t) {
  return new (t || TuiCalendarSheetPipeModule)();
};
TuiCalendarSheetPipeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiCalendarSheetPipeModule
});
TuiCalendarSheetPipeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCalendarSheetPipeModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiCalendarSheetPipe],
      exports: [TuiCalendarSheetPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiCalendarSheetPipe, TuiCalendarSheetPipeModule };
