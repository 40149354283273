import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { EventEmitter, ChangeDetectorRef, Component, ChangeDetectionStrategy, Optional, Inject, Self, Input, Output, HostListener, NgModule } from '@angular/core';
import * as i4 from '@taiga-ui/cdk';
import { TuiMonth, ALWAYS_FALSE_HANDLER, TUI_FIRST_DAY, TUI_LAST_DAY, tuiWatch, tuiIsString, TuiDayRange, tuiNullableSame, tuiObjectFromEntries, TuiDestroyService, tuiPure, TuiMapperPipeModule, TuiPreventDefaultModule } from '@taiga-ui/cdk';
import * as i2 from '@taiga-ui/core';
import { TUI_DEFAULT_MARKER_HANDLER, TUI_COMMON_ICONS, TuiCalendarModule, TuiSvgModule, TuiDataListModule } from '@taiga-ui/core';
import { MAX_DAY_RANGE_LENGTH_MAPPER } from '@taiga-ui/kit/constants';
import { TUI_CALENDAR_DATE_STREAM, TUI_OTHER_DATE_TEXT } from '@taiga-ui/kit/tokens';
import { takeUntil } from 'rxjs/operators';
import * as i1 from '@taiga-ui/kit/internal/primitive-calendar-range';
import { TuiPrimitiveCalendarRangeModule } from '@taiga-ui/kit/internal/primitive-calendar-range';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i5 from 'rxjs';
const _c0 = (a0, a1, a2, a3) => [a0, a1, a2, a3, false];
const _c1 = (a0, a1, a2, a3) => [a0, a1, a2, a3, true];
function TuiCalendarRangeComponent_tui_primitive_calendar_range_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tui-primitive-calendar-range", 2);
    i0.ɵɵlistener("dayClick", function TuiCalendarRangeComponent_tui_primitive_calendar_range_0_Template_tui_primitive_calendar_range_dayClick_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.onDayClick($event));
    });
    i0.ɵɵpipe(1, "tuiMapper");
    i0.ɵɵpipe(2, "tuiMapper");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("defaultViewedMonthFirst", ctx_r0.defaultViewedMonth)("disabledItemHandler", ctx_r0.calculatedDisabledItemHandler)("markerHandler", ctx_r0.markerHandler)("max", i0.ɵɵpipeBindV(1, 6, i0.ɵɵpureFunction4(18, _c0, ctx_r0.computedMax, ctx_r0.maxLengthMapper, ctx_r0.value, ctx_r0.maxLength)))("min", i0.ɵɵpipeBindV(2, 12, i0.ɵɵpureFunction4(23, _c1, ctx_r0.computedMin, ctx_r0.maxLengthMapper, ctx_r0.value, ctx_r0.maxLength)))("value", ctx_r0.value);
  }
}
function TuiCalendarRangeComponent_ng_template_1_button_5_tui_svg_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-svg", 9);
  }
  if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("src", ctx_r7.icons.check);
  }
}
function TuiCalendarRangeComponent_ng_template_1_button_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 7);
    i0.ɵɵlistener("click", function TuiCalendarRangeComponent_ng_template_1_button_5_Template_button_click_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r9);
      const item_r6 = restoredCtx.$implicit;
      const ctx_r8 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r8.onItemSelect(item_r6));
    })("keydown.enter.prevent", function TuiCalendarRangeComponent_ng_template_1_button_5_Template_button_keydown_enter_prevent_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r9);
      const item_r6 = restoredCtx.$implicit;
      const ctx_r10 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r10.onItemSelect(item_r6));
    })("keydown.space.prevent", function TuiCalendarRangeComponent_ng_template_1_button_5_Template_button_keydown_space_prevent_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r9);
      const item_r6 = restoredCtx.$implicit;
      const ctx_r11 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r11.onItemSelect(item_r6));
    });
    i0.ɵɵtext(1);
    i0.ɵɵtemplate(2, TuiCalendarRangeComponent_ng_template_1_button_5_tui_svg_2_Template, 1, 1, "tui-svg", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r6 = ctx.$implicit;
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵattribute("aria-checked", ctx_r5.isItemActive(item_r6));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", item_r6, " ");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r5.isItemActive(item_r6));
  }
}
const _c2 = (a0, a1, a2, a3, a4, a5) => [a0, a1, a2, a3, a4, a5];
function TuiCalendarRangeComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r13 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3)(1, "tui-calendar", 4);
    i0.ɵɵlistener("dayClick", function TuiCalendarRangeComponent_ng_template_1_Template_tui_calendar_dayClick_1_listener($event) {
      i0.ɵɵrestoreView(_r13);
      const ctx_r12 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r12.onDayClick($event));
    });
    i0.ɵɵpipe(2, "tuiMapper");
    i0.ɵɵpipe(3, "tuiMapper");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "tui-data-list", 5);
    i0.ɵɵtemplate(5, TuiCalendarRangeComponent_ng_template_1_button_5_Template, 3, 3, "button", 6);
    i0.ɵɵpipe(6, "tuiMapper");
    i0.ɵɵpipe(7, "async");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("disabledItemHandler", ctx_r1.calculatedDisabledItemHandler)("markerHandler", ctx_r1.markerHandler)("max", i0.ɵɵpipeBindV(2, 7, i0.ɵɵpureFunction4(28, _c0, ctx_r1.computedMax, ctx_r1.maxLengthMapper, ctx_r1.value, ctx_r1.maxLength)))("min", i0.ɵɵpipeBindV(3, 13, i0.ɵɵpureFunction4(33, _c1, ctx_r1.computedMin, ctx_r1.maxLengthMapper, ctx_r1.value, ctx_r1.maxLength)))("month", ctx_r1.computedMonth)("value", ctx_r1.value);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBindV(6, 19, i0.ɵɵpureFunction6(38, _c2, ctx_r1.items, ctx_r1.mapper, ctx_r1.min, ctx_r1.max, ctx_r1.minLength, i0.ɵɵpipeBind1(7, 26, ctx_r1.otherDateText$))));
  }
}
class TuiCalendarRangeComponent {
  constructor(valueChanges, cdr, destroy$, otherDateText$, icons) {
    this.otherDateText$ = otherDateText$;
    this.icons = icons;
    this.defaultViewedMonth = TuiMonth.currentLocal();
    this.disabledItemHandler = ALWAYS_FALSE_HANDLER;
    this.markerHandler = TUI_DEFAULT_MARKER_HANDLER;
    this.items = [];
    this.min = TUI_FIRST_DAY;
    this.max = TUI_LAST_DAY;
    this.minLength = null;
    this.maxLength = null;
    this.value = null;
    this.valueChange = new EventEmitter();
    this.previousValue = null;
    this.maxLengthMapper = MAX_DAY_RANGE_LENGTH_MAPPER;
    this.mapper = (items, min, max, minLength, otherDateText = '') => [...items.filter(item => (minLength === null || item.range.from.append(minLength).daySameOrBefore(item.range.to)) && (min === null || item.range.to.daySameOrAfter(min)) && (max === null || item.range.from.daySameOrBefore(max))), otherDateText];
    if (!valueChanges) {
      return;
    }
    valueChanges.pipe(tuiWatch(cdr), takeUntil(destroy$)).subscribe(value => {
      this.value = value;
    });
  }
  get computedMin() {
    var _a;
    return (_a = this.min) !== null && _a !== void 0 ? _a : TUI_FIRST_DAY;
  }
  get computedMax() {
    var _a;
    return (_a = this.max) !== null && _a !== void 0 ? _a : TUI_LAST_DAY;
  }
  onEsc(event) {
    var _a;
    if (event.key !== 'Escape' || !((_a = this.value) === null || _a === void 0 ? void 0 : _a.isSingleDay)) {
      return;
    }
    event.stopPropagation();
    this.value = this.previousValue;
  }
  get calculatedDisabledItemHandler() {
    return this.calculateDisabledItemHandler(this.disabledItemHandler, this.value, this.minLength);
  }
  get computedMonth() {
    return this.value ? this.value.to : this.defaultViewedMonth;
  }
  isItemActive(item) {
    const {
      activePeriod
    } = this;
    return tuiIsString(item) && activePeriod === null || activePeriod === item;
  }
  // TODO: investigate if it is used anywhere and (if not) delete it in v4.0
  onRangeChange(dayRange) {
    this.updateValue(dayRange);
  }
  onDayClick(day) {
    const {
      value
    } = this;
    this.previousValue = value;
    if (value === null || !value.isSingleDay) {
      this.value = new TuiDayRange(day, day);
      return;
    }
    this.updateValue(TuiDayRange.sort(value.from, day));
  }
  onItemSelect(item) {
    if (typeof item !== 'string') {
      this.updateValue(item.range.dayLimit(this.min, this.max));
      return;
    }
    if (this.activePeriod !== null) {
      this.updateValue(null);
    }
  }
  updateValue(value) {
    this.value = value;
    this.valueChange.emit(value);
  }
  get activePeriod() {
    return this.items.find(item => tuiNullableSame(this.value, item.range, (a, b) => a.from.daySame(b.from.dayLimit(this.min, this.max)) && a.to.daySame(b.to.dayLimit(this.min, this.max)))) || null;
  }
  calculateDisabledItemHandler(disabledItemHandler, value, minLength) {
    return item => {
      if (!(value === null || value === void 0 ? void 0 : value.isSingleDay) || !minLength) {
        return disabledItemHandler(item);
      }
      const negativeMinLength = tuiObjectFromEntries(Object.entries(minLength).map(([key, value]) => [key, -value]));
      const disabledBefore = value.from.append(negativeMinLength).append({
        day: 1
      });
      const disabledAfter = value.from.append(minLength).append({
        day: -1
      });
      const inDisabledRange = disabledBefore.dayBefore(item) && disabledAfter.dayAfter(item);
      return inDisabledRange || disabledItemHandler(item);
    };
  }
}
TuiCalendarRangeComponent.ɵfac = function TuiCalendarRangeComponent_Factory(t) {
  return new (t || TuiCalendarRangeComponent)(i0.ɵɵdirectiveInject(TUI_CALENDAR_DATE_STREAM, 8), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TuiDestroyService, 2), i0.ɵɵdirectiveInject(TUI_OTHER_DATE_TEXT), i0.ɵɵdirectiveInject(TUI_COMMON_ICONS));
};
TuiCalendarRangeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiCalendarRangeComponent,
  selectors: [["tui-calendar-range"]],
  hostBindings: function TuiCalendarRangeComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("keydown.capture", function TuiCalendarRangeComponent_keydown_capture_HostBindingHandler($event) {
        return ctx.onEsc($event);
      }, false, i0.ɵɵresolveDocument);
    }
  },
  inputs: {
    defaultViewedMonth: "defaultViewedMonth",
    disabledItemHandler: "disabledItemHandler",
    markerHandler: "markerHandler",
    items: "items",
    min: "min",
    max: "max",
    minLength: "minLength",
    maxLength: "maxLength",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([TuiDestroyService])],
  decls: 3,
  vars: 2,
  consts: [["automation-id", "tui-calendar-range__calendars", "tuiPreventDefault", "mousedown", 3, "defaultViewedMonthFirst", "disabledItemHandler", "markerHandler", "max", "min", "value", "dayClick", 4, "ngIf", "ngIfElse"], ["presets", ""], ["automation-id", "tui-calendar-range__calendars", "tuiPreventDefault", "mousedown", 3, "defaultViewedMonthFirst", "disabledItemHandler", "markerHandler", "max", "min", "value", "dayClick"], [1, "t-wrapper"], ["automation-id", "tui-calendar-range__calendar", "tuiPreventDefault", "mousedown", 3, "disabledItemHandler", "markerHandler", "max", "min", "month", "value", "dayClick"], ["automation-id", "tui-calendar-range__menu", "role", "menu", 1, "t-menu"], ["automation-id", "tui-calendar-range__menu__item", "role", "menuitemradio", "tuiOption", "", "tuiPreventDefault", "mousedown", 3, "click", "keydown.enter.prevent", "keydown.space.prevent", 4, "ngFor", "ngForOf"], ["automation-id", "tui-calendar-range__menu__item", "role", "menuitemradio", "tuiOption", "", "tuiPreventDefault", "mousedown", 3, "click", "keydown.enter.prevent", "keydown.space.prevent"], ["automation-id", "tui-calendar-range__checkmark", "class", "t-checkmark", 3, "src", 4, "ngIf"], ["automation-id", "tui-calendar-range__checkmark", 1, "t-checkmark", 3, "src"]],
  template: function TuiCalendarRangeComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiCalendarRangeComponent_tui_primitive_calendar_range_0_Template, 3, 28, "tui-primitive-calendar-range", 0)(1, TuiCalendarRangeComponent_ng_template_1_Template, 8, 45, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      const _r2 = i0.ɵɵreference(2);
      i0.ɵɵproperty("ngIf", !ctx.items.length)("ngIfElse", _r2);
    }
  },
  dependencies: [i1.TuiPrimitiveCalendarRangeComponent, i2.TuiCalendarComponent, i2.TuiDataListComponent, i2.TuiOptionComponent, i2.TuiSvgComponent, i3.NgIf, i4.TuiPreventDefaultDirective, i3.NgForOf, i4.TuiMapperPipe, i3.AsyncPipe],
  styles: ["[_nghost-%COMP%]{display:block}.t-wrapper[_ngcontent-%COMP%]{display:flex}.t-menu[_ngcontent-%COMP%]{width:11rem;border-left:1px solid var(--tui-base-03)}.t-checkmark[_ngcontent-%COMP%]{margin-left:auto;width:1rem;height:1rem}"],
  changeDetection: 0
});
__decorate([tuiPure], TuiCalendarRangeComponent.prototype, "calculateDisabledItemHandler", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCalendarRangeComponent, [{
    type: Component,
    args: [{
      selector: 'tui-calendar-range',
      templateUrl: './calendar-range.template.html',
      styleUrls: ['./calendar-range.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [TuiDestroyService]
    }]
  }], function () {
    return [{
      type: i5.Observable,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TUI_CALENDAR_DATE_STREAM]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i4.TuiDestroyService,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: i5.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_OTHER_DATE_TEXT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_COMMON_ICONS]
      }]
    }];
  }, {
    defaultViewedMonth: [{
      type: Input
    }],
    disabledItemHandler: [{
      type: Input
    }],
    markerHandler: [{
      type: Input
    }],
    items: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    minLength: [{
      type: Input
    }],
    maxLength: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    valueChange: [{
      type: Output
    }],
    onEsc: [{
      type: HostListener,
      args: ['document:keydown.capture', ['$event']]
    }],
    calculateDisabledItemHandler: []
  });
})();
class TuiCalendarRangeModule {}
TuiCalendarRangeModule.ɵfac = function TuiCalendarRangeModule_Factory(t) {
  return new (t || TuiCalendarRangeModule)();
};
TuiCalendarRangeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiCalendarRangeModule
});
TuiCalendarRangeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiMapperPipeModule, TuiPreventDefaultModule, TuiCalendarModule, TuiCalendarModule, TuiSvgModule, TuiDataListModule, TuiPrimitiveCalendarRangeModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCalendarRangeModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiMapperPipeModule, TuiPreventDefaultModule, TuiCalendarModule, TuiCalendarModule, TuiSvgModule, TuiDataListModule, TuiPrimitiveCalendarRangeModule],
      declarations: [TuiCalendarRangeComponent],
      exports: [TuiCalendarRangeComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiCalendarRangeComponent, TuiCalendarRangeModule };
