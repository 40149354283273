import * as i0 from '@angular/core';
import { Directive, Input, NgZone, ElementRef, Inject, Pipe, NgModule } from '@angular/core';
import * as i1 from '@angular/forms';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MASKITO_DEFAULT_OPTIONS, maskitoTransform, MASKITO_DEFAULT_ELEMENT_PREDICATE, Maskito } from '@maskito/core';
import { __awaiter } from 'tslib';
class MaskitoCva {
  constructor(accessor) {
    this.accessor = accessor;
    this.maskito = MASKITO_DEFAULT_OPTIONS;
    const original = accessor.writeValue.bind(accessor);
    accessor.writeValue = value => {
      var _a;
      original(maskitoTransform(String(value !== null && value !== void 0 ? value : ''), (_a = this.maskito) !== null && _a !== void 0 ? _a : MASKITO_DEFAULT_OPTIONS));
    };
  }
}
MaskitoCva.ɵfac = function MaskitoCva_Factory(t) {
  return new (t || MaskitoCva)(i0.ɵɵdirectiveInject(i1.DefaultValueAccessor));
};
MaskitoCva.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MaskitoCva,
  selectors: [["input", "maskito", ""], ["textarea", "maskito", ""]],
  hostBindings: function MaskitoCva_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("input", function MaskitoCva_input_HostBindingHandler($event) {
        return ctx.accessor._handleInput($event.target.value);
      })("blur", function MaskitoCva_blur_HostBindingHandler() {
        return ctx.accessor.onTouched();
      })("compositionstart", function MaskitoCva_compositionstart_HostBindingHandler() {
        return ctx.accessor._compositionStart();
      })("compositionend", function MaskitoCva_compositionend_HostBindingHandler($event) {
        return ctx.accessor._compositionEnd($event.target.value);
      });
    }
  },
  inputs: {
    maskito: "maskito"
  },
  features: [i0.ɵɵProvidersFeature([DefaultValueAccessor, {
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: DefaultValueAccessor
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaskitoCva, [{
    type: Directive,
    args: [{
      selector: 'input[maskito], textarea[maskito]',
      providers: [DefaultValueAccessor, {
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: DefaultValueAccessor
      }],
      host: {
        '(input)': '$any(this.accessor)._handleInput($event.target.value)',
        '(blur)': 'accessor.onTouched()',
        '(compositionstart)': '$any(this.accessor)._compositionStart()',
        '(compositionend)': '$any(this.accessor)._compositionEnd($event.target.value)'
      }
    }]
  }], function () {
    return [{
      type: i1.DefaultValueAccessor
    }];
  }, {
    maskito: [{
      type: Input
    }]
  });
})();
class MaskitoDirective {
  constructor(ngZone, elementRef) {
    this.ngZone = ngZone;
    this.elementRef = elementRef;
    this.maskedElement = null;
    this.maskito = MASKITO_DEFAULT_OPTIONS;
    this.maskitoElement = MASKITO_DEFAULT_ELEMENT_PREDICATE;
  }
  ngOnChanges() {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
      (_a = this.maskedElement) === null || _a === void 0 ? void 0 : _a.destroy();
      const predicate = this.maskitoElement;
      const predicateResult = yield predicate(this.elementRef.nativeElement);
      if (this.maskitoElement !== predicate) {
        // Ignore the result of the predicate if the
        // maskito element has changed before the predicate was resolved.
        return;
      }
      this.ngZone.runOutsideAngular(() => {
        var _a;
        this.maskedElement = new Maskito(predicateResult, (_a = this.maskito) !== null && _a !== void 0 ? _a : MASKITO_DEFAULT_OPTIONS);
      });
    });
  }
  ngOnDestroy() {
    var _a;
    (_a = this.maskedElement) === null || _a === void 0 ? void 0 : _a.destroy();
  }
}
MaskitoDirective.ɵfac = function MaskitoDirective_Factory(t) {
  return new (t || MaskitoDirective)(i0.ɵɵdirectiveInject(NgZone), i0.ɵɵdirectiveInject(ElementRef));
};
MaskitoDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MaskitoDirective,
  selectors: [["", "maskito", ""]],
  inputs: {
    maskito: "maskito",
    maskitoElement: "maskitoElement"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaskitoDirective, [{
    type: Directive,
    args: [{
      selector: '[maskito]'
    }]
  }], function () {
    return [{
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }];
  }, {
    maskito: [{
      type: Input
    }],
    maskitoElement: [{
      type: Input
    }]
  });
})();
class MaskitoPipe {
  transform(value, maskitoOptions) {
    return maskitoTransform(String(value !== null && value !== void 0 ? value : ''), maskitoOptions !== null && maskitoOptions !== void 0 ? maskitoOptions : MASKITO_DEFAULT_OPTIONS);
  }
}
MaskitoPipe.ɵfac = function MaskitoPipe_Factory(t) {
  return new (t || MaskitoPipe)();
};
MaskitoPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "maskito",
  type: MaskitoPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaskitoPipe, [{
    type: Pipe,
    args: [{
      name: 'maskito'
    }]
  }], null, null);
})();
class MaskitoModule {}
MaskitoModule.ɵfac = function MaskitoModule_Factory(t) {
  return new (t || MaskitoModule)();
};
MaskitoModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MaskitoModule
});
MaskitoModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaskitoModule, [{
    type: NgModule,
    args: [{
      declarations: [MaskitoDirective, MaskitoCva, MaskitoPipe],
      exports: [MaskitoDirective, MaskitoCva, MaskitoPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MaskitoCva, MaskitoDirective, MaskitoModule, MaskitoPipe };
