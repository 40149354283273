import * as i0 from '@angular/core';
import { ElementRef, NgZone, Directive, Inject, Self, Input, HostBinding, NgModule } from '@angular/core';
import { tuiTypedFromEvent, tuiZonefree } from '@taiga-ui/cdk/observables';
import { TuiDestroyService } from '@taiga-ui/cdk/services';
import { tuiIsElement, tuiGetScrollParent, tuiCanScroll } from '@taiga-ui/cdk/utils/dom';
import { filter, takeUntil, switchMap, tap } from 'rxjs/operators';
import * as i1 from 'rxjs';

/**
 * Directive to isolate scrolling, i.e. prevent body scroll behind modal dialog
 */
class TuiOverscrollDirective {
  constructor({
    nativeElement
  }, zone, destroy$) {
    this.mode = 'scroll';
    tuiTypedFromEvent(nativeElement, 'wheel', {
      passive: false
    }).pipe(filter(() => this.enabled), tuiZonefree(zone), takeUntil(destroy$)).subscribe(event => {
      this.processEvent(event, !!event.deltaY, event.deltaY ? event.deltaY < 0 : event.deltaX < 0);
    });
    tuiTypedFromEvent(nativeElement, 'touchstart', {
      passive: true
    }).pipe(switchMap(({
      touches
    }) => {
      let {
        clientX,
        clientY
      } = touches[0];
      let deltaX = 0;
      let deltaY = 0;
      let vertical;
      return tuiTypedFromEvent(nativeElement, 'touchmove', {
        passive: false
      }).pipe(filter(() => this.enabled), tap(event => {
        // We have to have it in tap instead of subscribe due to variables in closure
        const changedTouch = event.changedTouches[0];
        deltaX = clientX - changedTouch.clientX;
        deltaY = clientY - changedTouch.clientY;
        clientX = changedTouch.clientX;
        clientY = changedTouch.clientY;
        if (vertical === undefined) {
          vertical = Math.abs(deltaY) > Math.abs(deltaX);
        }
        this.processEvent(event, vertical, vertical ? deltaY < 0 : deltaX < 0);
      }));
    }), tuiZonefree(zone), takeUntil(destroy$)).subscribe();
  }
  get enabled() {
    return this.mode !== 'none';
  }
  get overscrollBehavior() {
    return this.enabled ? 'contain' : null;
  }
  processEvent(event, vertical, negative) {
    var _a;
    const {
      target,
      currentTarget,
      cancelable
    } = event;
    if (!cancelable || !tuiIsElement(target) || ((_a = target) === null || _a === void 0 ? void 0 : _a.type) === 'range') {
      return;
    }
    // This is all what's needed in Chrome/Firefox thanks to CSS overscroll-behavior
    if (this.mode === 'all' && (vertical && !currentTarget.contains(tuiGetScrollParent(target)) || !vertical && !currentTarget.contains(tuiGetScrollParent(target, false)))) {
      event.preventDefault();
      return;
    }
    // This is Safari/IE/Edge fallback
    if (vertical && (negative && !tuiCanScroll(target, currentTarget, true, false) || !negative && !tuiCanScroll(target, currentTarget, true, true))) {
      event.preventDefault();
      return;
    }
    if (!vertical && (negative && !tuiCanScroll(target, currentTarget, false, false) || !negative && !tuiCanScroll(target, currentTarget, false, true))) {
      event.preventDefault();
    }
  }
}
TuiOverscrollDirective.ɵfac = function TuiOverscrollDirective_Factory(t) {
  return new (t || TuiOverscrollDirective)(i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(NgZone), i0.ɵɵdirectiveInject(TuiDestroyService, 2));
};
TuiOverscrollDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiOverscrollDirective,
  selectors: [["", "tuiOverscroll", ""]],
  hostVars: 2,
  hostBindings: function TuiOverscrollDirective_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵstyleProp("overscroll-behavior", ctx.overscrollBehavior);
    }
  },
  inputs: {
    mode: ["tuiOverscroll", "mode"]
  },
  features: [i0.ɵɵProvidersFeature([TuiDestroyService])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiOverscrollDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiOverscroll]',
      providers: [TuiDestroyService]
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }];
  }, {
    mode: [{
      type: Input,
      args: ['tuiOverscroll']
    }],
    overscrollBehavior: [{
      type: HostBinding,
      args: ['style.overscrollBehavior']
    }]
  });
})();
class TuiOverscrollModule {}
TuiOverscrollModule.ɵfac = function TuiOverscrollModule_Factory(t) {
  return new (t || TuiOverscrollModule)();
};
TuiOverscrollModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiOverscrollModule
});
TuiOverscrollModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiOverscrollModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiOverscrollDirective],
      exports: [TuiOverscrollDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiOverscrollDirective, TuiOverscrollModule };
