import * as i0 from '@angular/core';
import { Directive, NgModule } from '@angular/core';
import { TUI_VALUE_ACCESSOR_PROVIDER } from '@taiga-ui/kit/providers';
class TuiValueAccessorDirective {}
TuiValueAccessorDirective.ɵfac = function TuiValueAccessorDirective_Factory(t) {
  return new (t || TuiValueAccessorDirective)();
};
TuiValueAccessorDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiValueAccessorDirective,
  selectors: [["", "tuiValueAccessor", ""]],
  features: [i0.ɵɵProvidersFeature([TUI_VALUE_ACCESSOR_PROVIDER])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiValueAccessorDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiValueAccessor]',
      providers: [TUI_VALUE_ACCESSOR_PROVIDER]
    }]
  }], null, null);
})();
class TuiValueAccessorModule {}
TuiValueAccessorModule.ɵfac = function TuiValueAccessorModule_Factory(t) {
  return new (t || TuiValueAccessorModule)();
};
TuiValueAccessorModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiValueAccessorModule
});
TuiValueAccessorModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiValueAccessorModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiValueAccessorDirective],
      exports: [TuiValueAccessorDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiValueAccessorDirective, TuiValueAccessorModule };
