import * as i0 from '@angular/core';
import { Pipe, Inject, NgModule } from '@angular/core';
import { TUI_MONTHS } from '@taiga-ui/core/tokens';
import { map } from 'rxjs/operators';
import * as i1 from 'rxjs';
class TuiMonthPipe {
  constructor(months$) {
    this.months$ = months$;
  }
  transform({
    month
  }) {
    return this.months$.pipe(map(months => months[month]));
  }
}
TuiMonthPipe.ɵfac = function TuiMonthPipe_Factory(t) {
  return new (t || TuiMonthPipe)(i0.ɵɵdirectiveInject(TUI_MONTHS, 16));
};
TuiMonthPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "tuiMonth",
  type: TuiMonthPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMonthPipe, [{
    type: Pipe,
    args: [{
      name: 'tuiMonth'
    }]
  }], function () {
    return [{
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_MONTHS]
      }]
    }];
  }, null);
})();
class TuiMonthPipeModule {}
TuiMonthPipeModule.ɵfac = function TuiMonthPipeModule_Factory(t) {
  return new (t || TuiMonthPipeModule)();
};
TuiMonthPipeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiMonthPipeModule
});
TuiMonthPipeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMonthPipeModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiMonthPipe],
      exports: [TuiMonthPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiMonthPipe, TuiMonthPipeModule };
