import * as i0 from '@angular/core';
import { ViewContainerRef, TemplateRef, Directive, Inject, Input, NgModule } from '@angular/core';
import { tuiClamp } from '@taiga-ui/cdk/utils/math';
const MAX_VALUE = 0x10000;
class TuiRepeatTimesContext {
  constructor($implicit) {
    this.$implicit = $implicit;
  }
}
/**
 * Directive similar to ngFor but using a number of repetitions rather than an array
 *
 * {@link TuiRepeatTimesDirective.tuiRepeatTimesOf requested number of times}.
 * {@link TuiRepeatTimesContext context} for every instance of the template inherits outer context and stores
 * {@link TuiRepeatTimesContext.$implicit index} of a template instance.
 */
class TuiRepeatTimesDirective {
  constructor(viewContainer, templateRef) {
    this.viewContainer = viewContainer;
    this.templateRef = templateRef;
  }
  set tuiRepeatTimesOf(count) {
    const safeCount = Math.floor(tuiClamp(count, 0, MAX_VALUE));
    const {
      length
    } = this.viewContainer;
    if (count < length) {
      this.removeContainers(length - count);
    } else {
      this.addContainers(safeCount);
    }
  }
  addContainers(count) {
    for (let index = this.viewContainer.length; index < count; index++) {
      this.viewContainer.createEmbeddedView(this.templateRef, new TuiRepeatTimesContext(index));
    }
  }
  removeContainers(amount) {
    for (let index = 0; index < amount; index++) {
      this.viewContainer.remove();
    }
  }
}
TuiRepeatTimesDirective.ɵfac = function TuiRepeatTimesDirective_Factory(t) {
  return new (t || TuiRepeatTimesDirective)(i0.ɵɵdirectiveInject(ViewContainerRef), i0.ɵɵdirectiveInject(TemplateRef));
};
TuiRepeatTimesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiRepeatTimesDirective,
  selectors: [["", "tuiRepeatTimes", "", "tuiRepeatTimesOf", ""]],
  inputs: {
    tuiRepeatTimesOf: "tuiRepeatTimesOf"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiRepeatTimesDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiRepeatTimes][tuiRepeatTimesOf]'
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef,
      decorators: [{
        type: Inject,
        args: [ViewContainerRef]
      }]
    }, {
      type: i0.TemplateRef,
      decorators: [{
        type: Inject,
        args: [TemplateRef]
      }]
    }];
  }, {
    tuiRepeatTimesOf: [{
      type: Input
    }]
  });
})();
class TuiRepeatTimesModule {}
TuiRepeatTimesModule.ɵfac = function TuiRepeatTimesModule_Factory(t) {
  return new (t || TuiRepeatTimesModule)();
};
TuiRepeatTimesModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiRepeatTimesModule
});
TuiRepeatTimesModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiRepeatTimesModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiRepeatTimesDirective],
      exports: [TuiRepeatTimesDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiRepeatTimesContext, TuiRepeatTimesDirective, TuiRepeatTimesModule };
