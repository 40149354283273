import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, NgModule } from '@angular/core';
import * as i4 from '@taiga-ui/cdk';
import { TuiMonth, TUI_FIRST_DAY, TUI_LAST_DAY, TuiFocusableModule } from '@taiga-ui/cdk';
import * as i1 from '@taiga-ui/core/components/primitive-spin-button';
import { TuiPrimitiveSpinButtonModule } from '@taiga-ui/core/components/primitive-spin-button';
import * as i2 from '@taiga-ui/core/components/link';
import { TuiLinkModule } from '@taiga-ui/core/components/link';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i5 from '@taiga-ui/core/pipes';
import { TuiMonthPipeModule } from '@taiga-ui/core/pipes';
function TuiPrimitiveYearMonthPaginationComponent_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.value.formattedYear, " ");
  }
}
function TuiPrimitiveYearMonthPaginationComponent_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 3);
    i0.ɵɵlistener("click", function TuiPrimitiveYearMonthPaginationComponent_ng_template_5_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.onYearClick());
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("tuiFocusable", false);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.value.formattedYear, " ");
  }
}
class TuiPrimitiveYearMonthPaginationComponent {
  constructor() {
    this.value = TuiMonth.currentLocal();
    this.min = TUI_FIRST_DAY;
    this.max = TUI_LAST_DAY;
    this.valueChange = new EventEmitter();
    this.yearClick = new EventEmitter();
  }
  get computedMin() {
    var _a;
    return (_a = this.min) !== null && _a !== void 0 ? _a : TUI_FIRST_DAY;
  }
  get computedMax() {
    var _a;
    return (_a = this.max) !== null && _a !== void 0 ? _a : TUI_LAST_DAY;
  }
  get prevMonthDisabled() {
    return this.value.monthSameOrBefore(this.computedMin);
  }
  get nextMonthDisabled() {
    return this.value.monthSameOrAfter(this.computedMax);
  }
  get oneYear() {
    const {
      computedMin,
      computedMax
    } = this;
    return computedMin.year === computedMax.year;
  }
  onYearClick() {
    this.yearClick.next(this.value);
  }
  onPrevMonthClick() {
    this.appendValueWithLimit({
      month: -1
    });
  }
  onNextMonthClick() {
    this.appendValueWithLimit({
      month: 1
    });
  }
  appendValueWithLimit(date) {
    const newMonth = this.value.append(date);
    const {
      computedMin,
      computedMax
    } = this;
    if (computedMin.monthSameOrAfter(newMonth)) {
      this.updateValue(computedMin);
      return;
    }
    this.updateValue(computedMax.monthSameOrBefore(newMonth) ? computedMax : newMonth);
  }
  updateValue(value) {
    if (this.value.monthSame(value)) {
      return;
    }
    this.value = value;
    this.valueChange.emit(value);
  }
}
TuiPrimitiveYearMonthPaginationComponent.ɵfac = function TuiPrimitiveYearMonthPaginationComponent_Factory(t) {
  return new (t || TuiPrimitiveYearMonthPaginationComponent)();
};
TuiPrimitiveYearMonthPaginationComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiPrimitiveYearMonthPaginationComponent,
  selectors: [["tui-primitive-year-month-pagination"]],
  inputs: {
    value: "value",
    min: "min",
    max: "max"
  },
  outputs: {
    valueChange: "valueChange",
    yearClick: "yearClick"
  },
  decls: 7,
  vars: 10,
  consts: [[3, "focusable", "leftDisabled", "rightDisabled", "leftClick", "rightClick"], [4, "ngIf", "ngIfElse"], ["button", ""], ["id", "year-btn", "automation-id", "tui-primitive-year-month-pagination__year-button", "tuiLink", "", "type", "button", 3, "tuiFocusable", "click"]],
  template: function TuiPrimitiveYearMonthPaginationComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "tui-primitive-spin-button", 0);
      i0.ɵɵlistener("leftClick", function TuiPrimitiveYearMonthPaginationComponent_Template_tui_primitive_spin_button_leftClick_0_listener() {
        return ctx.onPrevMonthClick();
      })("rightClick", function TuiPrimitiveYearMonthPaginationComponent_Template_tui_primitive_spin_button_rightClick_0_listener() {
        return ctx.onNextMonthClick();
      });
      i0.ɵɵtext(1);
      i0.ɵɵpipe(2, "async");
      i0.ɵɵpipe(3, "tuiMonth");
      i0.ɵɵtemplate(4, TuiPrimitiveYearMonthPaginationComponent_ng_container_4_Template, 2, 1, "ng-container", 1)(5, TuiPrimitiveYearMonthPaginationComponent_ng_template_5_Template, 2, 2, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      const _r2 = i0.ɵɵreference(6);
      i0.ɵɵproperty("focusable", false)("leftDisabled", ctx.prevMonthDisabled)("rightDisabled", ctx.nextMonthDisabled);
      i0.ɵɵadvance(1);
      i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 6, i0.ɵɵpipeBind1(3, 8, ctx.value)), " ");
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", ctx.oneYear)("ngIfElse", _r2);
    }
  },
  dependencies: [i1.TuiPrimitiveSpinButtonComponent, i2.TuiLinkComponent, i3.NgIf, i4.TuiFocusableDirective, i3.AsyncPipe, i5.TuiMonthPipe],
  styles: ["[_nghost-%COMP%]{display:block}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPrimitiveYearMonthPaginationComponent, [{
    type: Component,
    args: [{
      selector: 'tui-primitive-year-month-pagination',
      templateUrl: './primitive-year-month-pagination.template.html',
      styleUrls: ['./primitive-year-month-pagination.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    value: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    valueChange: [{
      type: Output
    }],
    yearClick: [{
      type: Output
    }]
  });
})();
class TuiPrimitiveYearMonthPaginationModule {}
TuiPrimitiveYearMonthPaginationModule.ɵfac = function TuiPrimitiveYearMonthPaginationModule_Factory(t) {
  return new (t || TuiPrimitiveYearMonthPaginationModule)();
};
TuiPrimitiveYearMonthPaginationModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiPrimitiveYearMonthPaginationModule
});
TuiPrimitiveYearMonthPaginationModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiFocusableModule, TuiPrimitiveSpinButtonModule, TuiLinkModule, TuiMonthPipeModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPrimitiveYearMonthPaginationModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiFocusableModule, TuiPrimitiveSpinButtonModule, TuiLinkModule, TuiMonthPipeModule],
      declarations: [TuiPrimitiveYearMonthPaginationComponent],
      exports: [TuiPrimitiveYearMonthPaginationComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiPrimitiveYearMonthPaginationComponent, TuiPrimitiveYearMonthPaginationModule };
