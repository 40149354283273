import * as i0 from '@angular/core';
import { ElementRef, Directive, Inject, Component, ChangeDetectionStrategy, Input, HostBinding, HostListener, NgModule } from '@angular/core';
import * as i1 from '@taiga-ui/cdk';
import { TUI_SCROLL_REF, tuiIsFirefox, tuiGetElementOffset, TUI_IS_IOS, TuiScrollControlsModule } from '@taiga-ui/cdk';
import { TUI_SCROLLABLE, TUI_SCROLL_INTO_VIEW } from '@taiga-ui/core/constants';
import { CSS, USER_AGENT } from '@ng-web-apis/common';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';

/** @deprecated import from `@taiga-ui/cdk` instead */
function TuiScrollbarComponent_tui_scroll_controls_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-scroll-controls", 2);
  }
}
const _c0 = ["*"];
const SCROLL_REF_SELECTOR = '[tuiScrollRef]';
/** @deprecated import from `@taiga-ui/cdk` instead */
class TuiScrollRefDirective {}
TuiScrollRefDirective.ɵfac = function TuiScrollRefDirective_Factory(t) {
  return new (t || TuiScrollRefDirective)();
};
TuiScrollRefDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiScrollRefDirective,
  selectors: [["", "tuiScrollRef", ""]],
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_SCROLL_REF,
    useExisting: ElementRef
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollRefDirective, [{
    type: Directive,
    args: [{
      selector: SCROLL_REF_SELECTOR,
      providers: [{
        provide: TUI_SCROLL_REF,
        useExisting: ElementRef
      }]
    }]
  }], null, null);
})();
class TuiScrollableDirective {
  constructor(el) {
    this.el = el;
  }
  ngOnInit() {
    this.el.nativeElement.dispatchEvent(new CustomEvent(TUI_SCROLLABLE, {
      bubbles: true,
      detail: this.el.nativeElement
    }));
  }
}
TuiScrollableDirective.ɵfac = function TuiScrollableDirective_Factory(t) {
  return new (t || TuiScrollableDirective)(i0.ɵɵdirectiveInject(ElementRef));
};
TuiScrollableDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiScrollableDirective,
  selectors: [["", "tuiScrollable", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollableDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiScrollable]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }];
  }, null);
})();

// TODO: Remove all legacy code in 4.0
class TuiScrollbarComponent {
  constructor(cssRef, el, userAgent, isIos) {
    this.cssRef = cssRef;
    this.el = el;
    this.userAgent = userAgent;
    this.isIos = isIos;
    this.isLegacy = !this.cssRef.supports('position', 'sticky') || tuiIsFirefox(this.userAgent) && !this.cssRef.supports('scrollbar-width', 'none');
    this.hidden = false;
    this.browserScrollRef = new ElementRef(this.el.nativeElement);
  }
  get delegated() {
    return this.browserScrollRef.nativeElement !== this.el.nativeElement;
  }
  get showScrollbars() {
    return !this.hidden && !this.isIos && (!this.isLegacy || this.delegated);
  }
  get showNative() {
    return this.isLegacy && !this.hidden && !this.delegated;
  }
  onScrollable(element) {
    this.browserScrollRef.nativeElement = element;
  }
  scrollIntoView(detail) {
    var _a;
    if (this.delegated) {
      return;
    }
    const {
      nativeElement
    } = this.browserScrollRef;
    const {
      offsetTop,
      offsetLeft
    } = tuiGetElementOffset(nativeElement, detail);
    const {
      clientHeight,
      clientWidth
    } = nativeElement;
    const {
      offsetHeight,
      offsetWidth
    } = detail;
    const scrollTop = offsetTop + offsetHeight / 2 - clientHeight / 2;
    const scrollLeft = offsetLeft + offsetWidth / 2 - clientWidth / 2;
    // ?. for our clients on Windows XP and Chrome 49
    (_a = nativeElement.scrollTo) === null || _a === void 0 ? void 0 : _a.call(nativeElement, scrollLeft, scrollTop);
  }
}
TuiScrollbarComponent.ɵfac = function TuiScrollbarComponent_Factory(t) {
  return new (t || TuiScrollbarComponent)(i0.ɵɵdirectiveInject(CSS), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(USER_AGENT), i0.ɵɵdirectiveInject(TUI_IS_IOS));
};
TuiScrollbarComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiScrollbarComponent,
  selectors: [["tui-scrollbar"]],
  hostVars: 2,
  hostBindings: function TuiScrollbarComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("tui-scrollable.stop", function TuiScrollbarComponent_tui_scrollable_stop_HostBindingHandler($event) {
        return ctx.onScrollable($event.detail);
      })("tui-scroll-into-view.stop", function TuiScrollbarComponent_tui_scroll_into_view_stop_HostBindingHandler($event) {
        return ctx.scrollIntoView($event.detail);
      });
    }
    if (rf & 2) {
      i0.ɵɵclassProp("_legacy", ctx.showNative);
    }
  },
  inputs: {
    hidden: "hidden"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_SCROLL_REF,
    deps: [TuiScrollbarComponent],
    useFactory: ({
      browserScrollRef
    }) => browserScrollRef
  }])],
  ngContentSelectors: _c0,
  decls: 3,
  vars: 3,
  consts: [["class", "t-bars", 4, "ngIf"], [1, "t-content"], [1, "t-bars"]],
  template: function TuiScrollbarComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, TuiScrollbarComponent_tui_scroll_controls_0_Template, 1, 0, "tui-scroll-controls", 0);
      i0.ɵɵelementStart(1, "div", 1);
      i0.ɵɵprojection(2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.showScrollbars);
      i0.ɵɵadvance(1);
      i0.ɵɵclassProp("t-content_delegated", ctx.delegated);
    }
  },
  dependencies: [i1.TuiScrollControlsComponent, i2.NgIf],
  styles: ["[_nghost-%COMP%]{position:relative;display:flex;isolation:isolate;overflow:auto}[_nghost-%COMP%]:not(._legacy){scrollbar-width:none;-ms-overflow-style:none}[_nghost-%COMP%]:not(._legacy)::-webkit-scrollbar, [_nghost-%COMP%]:not(._legacy)::-webkit-scrollbar-thumb{background:transparent;width:0;height:0}._legacy[_nghost-%COMP%]{overflow:overlay}@media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm){._legacy[_nghost-%COMP%]::-webkit-scrollbar, ._legacy[_nghost-%COMP%]::-webkit-scrollbar-thumb{width:1rem;height:1rem;border-radius:6.25rem;background-clip:padding-box;border:.375rem solid transparent}._legacy[_nghost-%COMP%]::-webkit-scrollbar{background-color:transparent}._legacy[_nghost-%COMP%]::-webkit-scrollbar-thumb{background-color:var(--tui-clear-hover)}._legacy[_nghost-%COMP%]::-webkit-scrollbar-thumb:hover{background-color:var(--tui-clear-active)}._legacy[_nghost-%COMP%]::-webkit-scrollbar-thumb:active{background-color:var(--tui-text-03)}}.t-content[_ngcontent-%COMP%]{isolation:isolate;flex:1;flex-basis:auto;width:100%;height:-webkit-max-content;height:max-content}.t-content_delegated[_ngcontent-%COMP%]{height:100%}.t-bars[_ngcontent-%COMP%]{color:var(--tui-text-01)}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollbarComponent, [{
    type: Component,
    args: [{
      selector: 'tui-scrollbar',
      templateUrl: './scrollbar.template.html',
      styleUrls: ['./scrollbar.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: TUI_SCROLL_REF,
        deps: [TuiScrollbarComponent],
        useFactory: ({
          browserScrollRef
        }) => browserScrollRef
      }]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [CSS]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [USER_AGENT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_IOS]
      }]
    }];
  }, {
    hidden: [{
      type: Input
    }],
    showNative: [{
      type: HostBinding,
      args: ['class._legacy']
    }],
    onScrollable: [{
      type: HostListener,
      args: [`${TUI_SCROLLABLE}.stop`, ['$event.detail']]
    }],
    scrollIntoView: [{
      type: HostListener,
      args: [`${TUI_SCROLL_INTO_VIEW}.stop`, ['$event.detail']]
    }]
  });
})();
class TuiScrollbarModule {}
TuiScrollbarModule.ɵfac = function TuiScrollbarModule_Factory(t) {
  return new (t || TuiScrollbarModule)();
};
TuiScrollbarModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiScrollbarModule
});
TuiScrollbarModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiScrollControlsModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollbarModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiScrollControlsModule],
      declarations: [TuiScrollbarComponent, TuiScrollRefDirective, TuiScrollableDirective],
      exports: [TuiScrollbarComponent, TuiScrollRefDirective, TuiScrollableDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { SCROLL_REF_SELECTOR, TuiScrollRefDirective, TuiScrollableDirective, TuiScrollbarComponent, TuiScrollbarModule };
